import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerPrimary from "../components/banners/bannerPrimary"
import Team from "../components/team"
import Bio from "../components/bio"
import Why from "../components/why"
import Reviews from "../components/reviews"
import Treatments from "../components/treatments"
import CallToAction from "../components/callToAction"
import FeaturedFaq from "../components/faqs/featuredFaq"
import Calander from "../components/calander"

export default function About() {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="We are a massage and sound therapy clinic located in the rolling foothills of Calgary and offer a unique relaxation experience to help you de-stress!"
      />
      <BannerPrimary
        to1="/"
        link1="Home"
        to2="/about"
        link2="About"
        title="about pacha mamma massage"
        description="We are a massage and sound therapy clinic located in the rolling foothills of Calgary and offer a unique relaxation experience to help you de-stress!"
      />
      <Team />
      <Why />
      <Bio />
      <Reviews />
      <Treatments />
      <CallToAction />
      <Calander />
      <FeaturedFaq />
    </Layout>
  )
}
